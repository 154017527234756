<template>
  <div class="main-content w-100 ml-0 m-main-content account-pages pt-2 feedback-body">
    <div class="m-container-fluid-menu feedback-form">
      <div class="container">
        <div class="row justify-content-center"> 
          <div class="col-md-8 col-lg-6 col-xl-5 login-main">
            <div class="card overflow-hidden">
              <div class="card-body pt-0">
                <div class="row flex-nowrap align-items-center">
                  <div class="col-md-6 feedback-title">
                    <h4 class="card-title pt-4">{{$t('Feedback')}}</h4>
                  </div>
                  <div class="col-md-6 food-icon text-right">
                    <img
                      :src="$auth.getFlatIcon()"
                      id="feedback_lg"
                      class="pt-3"
                    />
                  </div>
                </div>

                <form action="#" @submit.prevent="feedback_submit" class="pt-4">
                  <div class="form-group">
                    <input
                      id="restaurant_id"
                      name="restaurant_id"
                      type="hidden"
                      v-model="typeform.restaurant_id"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group">
                    <label>{{$t('Bill Number')}}</label>
                    <input
                      v-model="typeform.bill_number"
                      type="text"
                      class="form-control"
                      :placeholder="$t('Enter bill number')"
                      name="bill_number"
                    />
                  </div>

                  <div class="form-group">
                    <label>{{$t('Name')}}</label>
                    <input
                      v-model="$v.typeform.name.$model"
                      type="text"
                      class="form-control"
                      :placeholder="$t('Enter name')"
                      name="name"
                      :class="{ 'is-invalid': $v.typeform.name.$error }"
                    />
                    <div
                      v-if="$v.typeform.name.$error"
                      class="invalid-feedback cuisine-img"
                    >
                      <span v-if="!$v.typeform.name.required">{{$t('Enter name')}}</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>{{$t('Phone Number')}}</label>
                    <input
                      v-model="$v.typeform.phone_number.$model"
                      type="tel"
                      minlength="10"
                      maxlength="12"
                      class="form-control"
                      :placeholder="$t('Enter phone number')"
                      name="phone_number"
                      :class="{ 'is-invalid': $v.typeform.phone_number.$error }"
                    />
                    <div
                      v-if="$v.typeform.phone_number.$error"
                      class="invalid-feedback cuisine-img"
                    >
                      <span v-if="!$v.typeform.phone_number.required">{{
                        $t("Please enter contact number")
                      }}</span>
                      <span v-if="!$v.typeform.phone_number.numeric">{{
                        $t(
                          "This value should be a valid number"
                        )
                      }}</span>
                    </div>
                  </div>

                  <div class="wd100">
                    <div class="table-wrapper">
                      <div class="table-inner">
                        <table class="pricing-table">
                          <tbody>
                            <tr>
                              <th></th>
                              <th class="pl-2 w-70 text-center">{{$t('Best')}}</th>
                              <th class="pl-3 w-70 text-center">{{$t('Good')}}</th>
                              <th class="pl-3 w-70 text-center">{{$t('Average')}}</th>
                            </tr>
                            <tr class="highlight">
                              <td>
                                <b>{{$t('Ambience')}}</b>
                              </td>
                              <td
                                class="
                                  fed-smile
                                  icon-thread
                                  best-icon
                                  text-center
                                "
                              >
                                <input
                                  type="radio"
                                  name="optionsRadios"
                                  id="optionsRadios1"
                                  value="1"
                                  v-model="typeform.ambience"
                                />
                                <label class="radio m-0" for="optionsRadios1">
                                  <i class="far fa-laugh"></i>
                                </label>
                              </td>
                              <td
                                class="
                                  fed-smile
                                  icon-thread
                                  good-icon
                                  text-center
                                "
                              >
                                <input
                                  type="radio"
                                  name="optionsRadios"
                                  id="optionsRadios1"
                                  value="2"
                                  v-model="typeform.ambience"
                                />
                                <label class="radio m-0" for="optionsRadios1">
                                  <i class="far fa-smile"></i>
                                </label>
                              </td>
                              <td
                                class="
                                  fed-smile
                                  icon-thread
                                  average-icon
                                  text-center
                                "
                              >
                                <input
                                  type="radio"
                                  name="optionsRadios"
                                  id="optionsRadios1"
                                  value="3"
                                  v-model="typeform.ambience"
                                />
                                <label class="radio m-0" for="optionsRadios1">
                                  <i class="far fa-meh"></i>
                                </label>
                              </td>
                            </tr>
                            <tr class="highlight">
                              <td>
                                <b>{{$t('Time Taken To Serve')}}</b>
                              </td>
                              <td
                                class="
                                  fed-smile
                                  icon-thread
                                  best-icon
                                  text-center
                                "
                              >
                                <input
                                  type="radio"
                                  name="optionsRadios1"
                                  id="optionsRadios4"
                                  value="1"
                                  v-model="typeform.time_taken_to_serve"
                                />
                                <label class="radio m-0" for="optionsRadios4">
                                  <i class="far fa-laugh"></i>
                                </label>
                              </td>
                              <td
                                class="
                                  fed-smile
                                  icon-thread
                                  good-icon
                                  text-center
                                "
                              >
                                <input
                                  type="radio"
                                  name="optionsRadios1"
                                  id="optionsRadios4"
                                  value="2"
                                  v-model="typeform.time_taken_to_serve"
                                />
                                <label class="radio m-0" for="optionsRadios4">
                                  <i class="far fa-smile"></i>
                                </label>
                              </td>
                              <td
                                class="
                                  fed-smile
                                  icon-thread
                                  average-icon
                                  text-center
                                "
                              >
                                <input
                                  type="radio"
                                  name="optionsRadios1"
                                  id="optionsRadios4"
                                  value="3"
                                  v-model="typeform.time_taken_to_serve"
                                />
                                <label class="radio m-0" for="optionsRadios4">
                                  <i class="far fa-meh"></i>
                                </label>
                              </td>
                            </tr>
                            <tr class="highlight">
                              <td>
                                <b>{{$t('Food Taste')}}</b>
                              </td>
                              <td
                                class="
                                  fed-smile
                                  icon-thread
                                  best-icon
                                  text-center
                                "
                              >
                                <input
                                  type="radio"
                                  name="optionsRadios2"
                                  id="optionsRadios7"
                                  value="1"
                                  v-model="typeform.food_taste"
                                />
                                <label class="radio m-0" for="optionsRadios7">
                                  <i class="far fa-laugh"></i>
                                </label>
                              </td>
                              <td
                                class="
                                  fed-smile
                                  icon-thread
                                  good-icon
                                  text-center
                                "
                              >
                                <input
                                  type="radio"
                                  name="optionsRadios2"
                                  id="optionsRadios7"
                                  value="2"
                                  v-model="typeform.food_taste"
                                />
                                <label class="radio m-0" for="optionsRadios7">
                                  <i class="far fa-smile"></i>
                                </label>
                              </td>
                              <td
                                class="
                                  fed-smile
                                  icon-thread
                                  average-icon
                                  text-center
                                "
                              >
                                <input
                                  type="radio"
                                  name="optionsRadios2"
                                  id="optionsRadios7"
                                  value="3"
                                  v-model="typeform.food_taste"
                                />
                                <label class="radio m-0" for="optionsRadios7">
                                  <i class="far fa-meh"></i>
                                </label>
                              </td>
                            </tr>
                            <tr class="highlight">
                              <td>
                                <b>{{$t('Staff Courteousness')}}</b>
                              </td>
                              <td
                                class="
                                  fed-smile
                                  icon-thread
                                  best-icon
                                  text-center
                                "
                              >
                                <input
                                  type="radio"
                                  name="optionsRadios3"
                                  id="optionsRadios10"
                                  value="1"
                                  v-model="typeform.staff_courteousness"
                                />
                                <label class="radio m-0" for="optionsRadios10">
                                  <i class="far fa-laugh"></i>
                                </label>
                              </td>
                              <td
                                class="
                                  fed-smile
                                  icon-thread
                                  good-icon
                                  text-center
                                "
                              >
                                <input
                                  type="radio"
                                  name="optionsRadios3"
                                  id="optionsRadios10"
                                  value="2"
                                  v-model="typeform.staff_courteousness"
                                />
                                <label class="radio m-0" for="optionsRadios10">
                                  <i class="far fa-smile"></i>
                                </label>
                              </td>
                              <td
                                class="
                                  fed-smile
                                  icon-thread
                                  average-icon
                                  text-center
                                "
                              >
                                <input
                                  type="radio"
                                  name="optionsRadios3"
                                  id="optionsRadios10"
                                  value="3"
                                  v-model="typeform.staff_courteousness"
                                />
                                <label class="radio m-0" for="optionsRadios10">
                                  <i class="far fa-meh"></i>
                                </label>
                              </td>
                            </tr>
                            <tr class="highlight">
                              <td>
                                <b>{{$t('Food Quality')}}</b>
                              </td>
                              <td
                                class="
                                  fed-smile
                                  icon-thread
                                  best-icon
                                  text-center
                                "
                              >
                                <input
                                  type="radio"
                                  name="optionsRadios4"
                                  id="optionsRadios13"
                                  value="1"
                                  v-model="typeform.food_quality"
                                />
                                <label class="radio m-0" for="optionsRadios13">
                                  <i class="far fa-laugh"></i>
                                </label>
                              </td>
                              <td
                                class="
                                  fed-smile
                                  icon-thread
                                  good-icon
                                  text-center
                                "
                              >
                                <input
                                  type="radio"
                                  name="optionsRadios4"
                                  id="optionsRadios13"
                                  value="2"
                                  v-model="typeform.food_quality"
                                />
                                <label class="radio m-0" for="optionsRadios13">
                                  <i class="far fa-smile"></i>
                                </label>
                              </td>
                              <td
                                class="
                                  fed-smile
                                  icon-thread
                                  average-icon
                                  text-center
                                "
                              >
                                <input
                                  type="radio"
                                  name="optionsRadios4"
                                  id="optionsRadios13"
                                  value="3"
                                  v-model="typeform.food_quality"
                                />
                                <label class="radio m-0" for="optionsRadios13">
                                  <i class="far fa-meh"></i>
                                </label>
                              </td>
                            </tr>
                            <tr class="highlight">
                              <td class="pt-2">
                                <b
                                  >{{$t('Did the manager or captain visit your table')}}?</b
                                >
                              </td>
                              <td class="pl-2 manager-visit">
                                <b-form-radio-group>
                                  <b-form-radio
                                    class="mb-1"
                                    value="1"
                                    v-model="typeform.manager_or_captain_visit_your_table"
                                    >{{$t('Yes')}}</b-form-radio
                                  >
                                </b-form-radio-group>
                              </td>
                              <td>
                                <b-form-radio-group>
                                  <b-form-radio
                                    class="mb-1"
                                    value="0"
                                    v-model="
                                      typeform.manager_or_captain_visit_your_table
                                    "
                                    >{{$t('No')}}</b-form-radio
                                  >
                                </b-form-radio-group>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div class="form-group mt-2">
                    <label>{{$t('Your Experience')}}</label>
                    <div>
                      <textarea
                        v-model="typeform.feedback"
                        class="form-control"
                        :placeholder="$t('Write Your Experience.....')"
                        name="feedback"
                        rows="5"
                      ></textarea>
                    </div>
                  </div>

                  <div class="form-group mt-3">
                    <label>{{$t('Birthday')}}</label>
                    <div>
                      <date-picker
                        v-model="typeform.birthday"
                        value-type="format"
                        :disabled-date="disabledAfterTodayAndAfterAWeek"
                      ></date-picker>
                    </div>
                  </div>

                  <div class="form-group">
                   <label>{{$t('Anniversary')}}</label>
                    <div>
                      <date-picker
                        v-model="typeform.anniversary"
                        value-type="format"
                        :disabled-date="disabledAfterTodayAndAfterAWeek"
                      ></date-picker>
                    </div>
                  </div>

                  <div class="form-group mb-0">
                    <div class="feedbacks">
                      <button
                        class="btn btn-primary"
                        type="button"
                        @click="feedback_submit"
                      >
                        {{$t('Submit')}}
                      </button>
                      <button class="btn btn-secondary float-right" type="button">
                        {{$t('Cancel')}}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <!-- end card-body -->
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, numeric } from "vuelidate/lib/validators";
import Feedback from "../apis/feedback";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
    components: { DatePicker },
    data(){
        return{
            typeform:{
                bill_number:"",
                name:"",
                phone_number: "",
                ambience:"",
                time_taken_to_serve:"",
                food_taste:"",
                staff_courteousness:"",
                food_quality:"",
                manager_or_captain_visit_your_table:"",
                feedback:"",
                birthday:"",
                anniversary:"",
                
            },
            maxPhoneNumber: 12,
        }
    },
    
    methods:{
      disabledAfterTodayAndAfterAWeek(date) {
          return date > new Date(new Date().setHours(0, 0, 0, 0));
        },
        feedback_submit(e) {
            this.$v.$touch();
            if(this.$v.typeform.$invalid){
                return false;
            }else{
                Feedback.AddFeedback({
                restaurant_id:this.$route.params.restaurantid,
                name:this.typeform.name,
                phone_number:this.typeform.phone_number,
                feedback:this.typeform.feedback,
                ambience:this.typeform.ambience,
                time_taken_to_serve:this.typeform.time_taken_to_serve,
                food_taste:this.typeform.food_taste,
                staff_courteousness:this.typeform.staff_courteousness,
                food_quality:this.typeform.food_quality,
                manager_or_captain_visit_your_table:this.typeform.manager_or_captain_visit_your_table,
                birthday:this.typeform.birthday,
                anniversary:this.typeform.anniversary,
                bill_number:this.typeform.bill_number,
            }).then((response)=>{
                if(response.data.code == 200){
                    this.typeform = {
                      name: "",
                      restaurant_id: this.$route.restaurant_id,
                      phone_number: "",
                      feedback:"",
                      ambience:"",
                      time_taken_to_serve:"",
                      food_taste:"",
                      staff_courteousness:"",
                      food_quality:"",
                      manager_or_captain_visit_your_table:"",
                      birthday:"",
                      anniversary:"",
                      bill_number:""
                    }
                    this.$v.typeform.$reset();
                    this.$toasted.success(this.$t('Feedback Add Successfully'),{ duration: 2000 });
                }else{
                    this.$toasted.error(this.$t('Feedback Not Added ! Please Try Again'),{ duration: 2000 });
                }
            });
            }
            return false;;
        },

        cancel(){
          this.$router.push({ path:'Home' });
        }
    },
    validations: {
        typeform: {
            phone_number: {
                required,
                numeric
            },
            name:{
                required,
            }
      }
    },
}
</script>
<style scoped>
  .main-content {
      overflow: visible !important;
  }
  .account-pages .feedback-form {
    padding-top: 23px;
  }
  .login-main {
    margin-top: -25px;
  }
  .form-group label {
    color: rgba(0,0,0,0.6);
    font-weight: 600;
  }
  .feedback-title .card-title {
    font-size: 15px;
    margin: 0 0 7px;
    font-weight: 600;
  }
  .feedback-title h4{
    color: #495057;
  }
  .w-70{
      width: 70px !important;
      color: #495057;
  }
  .food-icon img{
    height: 80px;
  }
  label.radio .bx{
    font-size: 30px;
  }
  tr.highlight{
    height: 60px;
  }
  .feedback-body, .feedback-body input.form-control, .feedback-body textarea.form-control{
    font-size: 13px !important;
  } 
  .highlight b{
    font-weight: 700;
    color: #4A505D;
  }
  .fed-smile {
    font-size: 21px !important;
    padding-left: 3px;
  }
  .icon-thread input[type="radio"] {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 30px;
    cursor: pointer;
  }
  .icon-thread input[type="radio"] + label {
    margin: 0.6em;
    color: #b1b1b1;
    text-shadow: 1px 1px #fff;
  }
  label.radio .bx[data-v-ef8fdf0e] {
    font-size: 30px;
  } 
  .bx-happy-alt:before {
    content: "\EA72";
  }
  .bx {
    font-family: boxicons!important;
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    display: inline-block;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .manager-visit .custom-control label{
    font-weight: 500;
  }
  .mx-datepicker {
    width: 100%!important;
  }
  .mx-datepicker .mx-icon-calendar {
    color: #495057!important;
  }
  .btn-primary {
    color: #fff;
    background-color: #556ee6;
    border-color: #556ee6;
    font-size: 14px;
  }
  .btn-secondary{
    font-size: 14px;
  }
  .pricing-table .fed-smile label i{
    font-size: 30px;
  }
  .best-icon input[type="radio"]:checked + label {
    color: #008000;
  }
  .good-icon input[type="radio"]:checked + label {
    color: #8B8000;
  }
  .average-icon input[type="radio"]:checked + label {
    color: #bf0000;
  } 
</style>